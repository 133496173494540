import React, {Component} from 'react';
import {COMPANY, COMPANY_SOCIAL} from "../Library/Constants";
import {Link} from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <>
                <footer id="footer-4" className="footer division">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="footer-info mb-40">
                                    <img className="footer-logo" src="/assets/images/logo.png" alt="footer-logo"/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2">
                                <div className="footer-links mb-40">
                                    <h6 className="h6-xl">Connect With Us</h6>
                                    <ul className="footer-socials text-secondary ico-25 text-center clearfix">
                                        <li><Link to={{pathname: `https://www.facebook.com/${COMPANY_SOCIAL.Facebook}?utm_source=site&utm_medium=footer&utm_campaign=info`}} target="_blank"><span className="flaticon-facebook"/></Link></li>
                                        <li><Link to={{pathname: `https://www.twitter.com/${COMPANY_SOCIAL.Twitter}?utm_source=site&utm_medium=footer&utm_campaign=info`}} target="_blank"><span className="flaticon-twitter"/></Link></li>
                                        <li><Link to={{pathname: `https://www.instagram.com/${COMPANY_SOCIAL.Instagram}?utm_source=site&utm_medium=footer&utm_campaign=info`}} target="_blank"><span className="flaticon-instagram"/></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="bottom-footer">
                            <div className="row row-cols-1 row-cols-md-2 d-flex align-items-center">
                                <div className="col">
                                    <div className="footer-copyright">
                                        <p>&copy; 2007 - 2021 {COMPANY.NAME}. All Rights Reserved</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <ul className="bottom-footer-list text-secondary text-end">
                                        <li className="first-li"><p><Link to="/privacy_policy">Privacy Policy</Link></p></li>
                                        <li className="last-li"><p><Link to="/terms_conditions">Terms & Conditions</Link></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;