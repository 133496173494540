import React from 'react';
import {Link} from "react-router-dom";
import Preloader from "../Library/Preloader";

function Error404() {
    return (
        <>
            <Preloader state={false}/>
            <section id="hero-24" className="bg-tra-blue hero-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                            <div className="hero-24-txt text-center">
                                <div className="rel hero-24-img">
                                    <img className="img-fluid" src="/assets/images/error-404.png" alt="error-404-img"/>
                                        <h2 className="tra-header">404</h2>
                                </div>
                                <h2 className="h2-md">Page Not Found</h2>
                                <h5 className="h5-md">The page you are looking for might have been moved , renamed or might never existed </h5>
                                <Link to="/" className="btn btn-skyblue tra-grey-hover">Back To Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Error404;