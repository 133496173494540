import $ from "jquery";

let preloader = $('#loading'), loader = preloader.find('#loading-center-absolute');

function Preloader({state}) {
    if (state) {
        loader.show();
        preloader.show();
    } else {
        setTimeout(() => {
            loader.fadeOut();
            preloader.delay(400).fadeOut('slow');
        }, 500)
    }
    return null;
}

Preloader.defaultProps = {
    state: true
}

export default Preloader;
