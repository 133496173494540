import React from 'react';
import {Helmet} from "react-helmet";
import {COMPANY} from "./Constants";

function DocumentTitler({title}) {
    return (
        <>
            <Helmet>
                <title>{title !== "" ? `${title} - ${COMPANY.NAME}` : COMPANY.NAME}</title>
            </Helmet>
        </>
    );
}

DocumentTitler.defaultProps = {
    title: ""
}
export default DocumentTitler;