import React from "react";
import {withRouter} from "react-router-dom"
import $ from "jquery";

let preloader = $('#loading'), loader = preloader.find('#loading-center-absolute');

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
            loader.show();
            preloader.show();
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);