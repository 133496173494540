export const CONSTANTS = {
    URL: "https://apis.khaleejinfotech.com/main/",
}
export const COMPANY = {
    NAME: "KHALEEJ Infotech",
    CONTACT: "7737678880",
    EMAIL: "contact@khaleejinfotech.com"
}

export const COMPANY_SOCIAL = {
    Facebook: "khaleejinfotech",
    Twitter: "khaleejinfotech",
    Instagram: "khaleejinfotech"
}

export const PROJECT_TYPES = {
    DESIGN: "Graphic Design",
    MOB_APP: "Mobile App (Android)",
    WEBSITE: "Website",
    WEB_APP: "Web Application",
    NETWORK: "IT/Networking"
}