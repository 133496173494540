import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from "./Components/Header";
import Main from "./Components/Main";
import Footer from "./Components/Footer";
import Error404 from "./Components/Error_404";
import ScrollToTop from "./Library/ScrollToTop";
import About from "./Components/Pages/About";
import Services from "./Components/Pages/Services";
import Contact from "./Components/Pages/Contact";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import TermsCondition from "./Components/Pages/TermsCondition";


class App extends React.Component {
    render() {
        return (
            <div id="page" className="page">
                <Router>
                    <Header/>
                    <ScrollToTop/>
                    <Switch>
                        <Route exact path="/" component={Main}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/services" component={Services}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/privacy_policy" component={PrivacyPolicy}/>
                        <Route exact path="/terms_conditions" component={TermsCondition}/>
                        <Route component={Error404}/>
                    </Switch>
                    <Footer/>
                </Router>
            </div>
        );
    }
}

export default App;
