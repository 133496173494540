import React from 'react';
import Preloader from "../../Library/Preloader";
import OwlCarousel from "react-owl-carousel";
import DocumentTitler from "../../Library/DocumentTitler";

const reviews = [
    {
        review: "We used KHALEEJ Infotech logo creation services along with their app development services. They have been a pleasure to work with and have been responsive to all questions asked. We have recommended them to some of our clients and will continue to do so as we have been very happy with the logos and mobile app that they have created for us.",
        person: "Absar Alam",
        company: "KHOJKAR"
    },
    {
        review: "We have been using KHALEEJ Infotech services for the last four years and are happy with the quality of service they provide. Social Media management requires a 24/7 ownership and we have been able to get that from KHALEEJ Infotech. Thanks to KHALEEJ Infotech & their team for being a great partner in our journey.",
        person: "Saleem Khilji",
        company: "Adarsh Muslim Newspaper"
    },
    {
        review: "We are very happy that we chose KHALEEJ Infotech for the development of our new website. It looks great, easy to navigate and updates are done in no time. Our customers love it.",
        person: "Usama Khilji",
        company: "Adarsh Muslim Publication"
    },
    {
        review: "We have worked with KHALEEJ Infotech for many years and KHALEEJ Infotech has developed several websites for us. Their expertise in website development, graphic design is unmatchable in the industry along with their quality of work and ongoing support.",
        person: "Mr. Atique",
        company: "Marwar Muslim Educational &amp; Welfare Society"
    },
    {
        review: "Fantastic work! I am just impressed by their service quality and working strategy. I hired them for the development of my online store and they have satisfied me to the full by delivering the exceptional solution. They have a great expertise, are dedicated, attentive, talented and care much about the client needs. Highly recommended.",
        person: "Kamal Gupta",
        company: "KBG Mart"
    },
    {
        review: "It was and still is very nice to work with KHALEEJ Infotech. The team is very polite and has given me quick answers, always trying to meet our needs. The quality of the work is very good!",
        person: "Shadab Khan",
        company: "Fashprix"
    },
    {
        review: "All work done by KHALEEJ Infotech are exceptional and I couldn't have it any other way. They delivered as promise and the work is as you requested.",
        person: "Aazam Khan",
        company: "KabaadKhana App"
    }
]


function About(props) {
    return (
        <>
            <Preloader state={false}/>
            <DocumentTitler title="About Us"/>
            <section className=" inner-page-hero  bg-lightgrey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-title title-02 mb-85">
                                <h3 className="h3-xl">About Us</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-inner mt-50 division">
                <div className="container">
                    <div className="col-md-12 pb-55">
                        <div className="row row-cols-1 ">
                            <div className="col">
                                <p><strong>KHALEEJ Infotech</strong> is a full-service web design company founded in 2007. The company develops and improves
                                    professional websites for customers across a wide range of industries, including advertising, communications, fashion,
                                    e-commerce, construction and real estate.</p>

                                <p>The company’s expertise is its commitment to offer custom-made web design for every client, from small business websites to
                                    giant e-commerce websites. By meeting the growing technological and design needs of its various clients at reasonable
                                    prices.</p>

                                <p>We offer high-quality premium website design services that are customized according to a client’s requirements. We work with
                                    clients closely to understand each company’s online target audience and address their specific needs. On every project, we
                                    engage multiple web designers to give more design options. Having more options available to you means that you can mix and
                                    match elements from different concepts and incorporate them into the final design.</p>
                            </div>
                            <div className="col mt-95">
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-8">
                                        <div className="section-title title-02 mb-75">
                                            <h3 className="h3-xl">Don't trust our words. See what our clients say about us</h3>
                                        </div>
                                    </div>
                                </div>
                                <OwlCarousel items={3} loop={true} autoplay={true} autoplayTimeout={4500} autoplayHoverPause={true} smartSpeed={1500}
                                             responsive={{0: {items: 1}, 767: {items: 1}, 768: {items: 2}, 991: {items: 3}, 1000: {items: 3}}} dots={false}>
                                    {reviews.map((review,key) => {
                                        return (
                                            <div className="review-1" key={key}>
                                                <div className="review-1-ico ico-25">
                                                    <span className="flaticon-left-quote"/>
                                                </div>
                                                <div className="review-1-txt">
                                                    <p className="p-lg" dangerouslySetInnerHTML={{__html: review.review}}/>
                                                    <div className="author-data clearfix">
                                                        <div className="p-0 review-author">
                                                            <h6 className="h6-xl" dangerouslySetInnerHTML={{__html: review.person}}/>
                                                            <p className="p-md" dangerouslySetInnerHTML={{__html: review.company}}/>
                                                            <div className="review-rating ico-15 yellow-color">
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-half-empty"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;