import React, {Component} from 'react';
import Preloader from "../../Library/Preloader";
import {Link} from "react-router-dom";
import {COMPANY, CONSTANTS} from "../../Library/Constants";
import Axios from 'axios'
import $ from 'jquery'
import 'react-toastify/dist/ReactToastify.css'
import {Messaging} from "react-cssfx-loading";
import {toast, ToastContainer} from "react-toastify";
import DocumentTitler from "../../Library/DocumentTitler";

const initState = {
    isFormSubmitting: false,
    question: "",
    name: "",
    contact: "",
    email: "",
    message: ""

}

const btnSubmitting = {color: "#1d293f !important", backgroundColor: "transparent", borderColor: "#ccc !important"}
const btnRest = {color: "#fff!important", backgroundColor: "#0195ff", borderColor: "#0195ff !important"}


class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = initState
    }

    handleChange(e) {
        this.setState({[e.target.id]: e.target.value});
    }

    reachUs(e) {
        e.preventDefault();
        let btnSubmit = $(e.target).find("#submit");
        btnSubmit.css(btnSubmitting);
        this.setState({isFormSubmitting: true});
        Axios.post(`${CONSTANTS.URL}reachUs`, this.state, {
            headers: {
                'Authorization': 'demoContent'
            }
        }).then(res => {
            this.setState(initState);
            btnSubmit.css(btnRest);
            if (!res.data.error) {
                toast.success(res.data.msg)
            } else {
                toast.dark(res.data.msg)
            }
        });
    }

    render() {
        return (
            <>
                <Preloader state={false}/>
                <DocumentTitler title="Contact Us"/>
                <ToastContainer/>
                <section className=" inner-page-hero  bg-lightgrey">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="section-title title-02 mb-85">
                                    <h3 className="h3-xl">Contact Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contacts-2" className="bg-snow wide-50 contacts-section division">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="section-title title-02">
                                            <h2 className="h4-xs">Other ways to reach us</h2>
                                        </div>
                                    </div>
                                </div>
                                <section id="cta-8" className="bg-snow wide-100 cta-section division">
                                    <div className="container">
                                        <div className="cta-8-wrapper pc-25">
                                            <div className="col mb-50">
                                                <Link to={{pathname: `tel:${COMPANY.CONTACT}`}} target="_top">
                                                    <div className="cta-box cta-top-box bg-white wow fadeInUp" style={{padding: "30px 20px 20px"}}>
                                                        <div className="cta-ico">
                                                            <div className="ico-40"><span className="flaticon-phone-call"/></div>
                                                        </div>
                                                        <div className="cta-txt mt-5">
                                                            <h5 className="h5-md p-0 m-0">+91 77376 78880</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col mb-50">
                                                <Link to={{pathname: `mailto:${COMPANY.EMAIL}`}} target="_top">
                                                    <div className="cta-box bg-white wow fadeInUp" style={{padding: "30px 20px 20px"}}>
                                                        <div className="cta-ico">
                                                            <div className="ico-40"><span className="flaticon-email"/></div>
                                                        </div>
                                                        <div className="cta-txt mt-5">
                                                            <h5 className="h5-md p-0 m-0">contact@khaleejinfotech.com</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="col-md-6">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="section-title title-02 mb-60">
                                            <h2 className="h4-xs">Have a question? Need help?<br/>Don't hesitate, drop us a line</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="form-holder">
                                            <form className="row contact-form" onSubmit={(e) => this.reachUs(e)}>
                                                <div className="col-md-12 input-subject">
                                                    <p className="p-lg">This question is about: </p>
                                                    <select id="question" className="form-select subject" required onChange={(e) => this.handleChange(e)}>
                                                        <option value="">This question is about...*</option>
                                                        <option value="Website Development">Website Development</option>
                                                        <option value="Mobile App Development">Mobile App Development</option>
                                                        <option value="Web Hosting">Web Hosting</option>
                                                        <option value="Web Application Development">Web Application Development</option>
                                                        <option value="IT/Network Troubleshooting">IT/Network Troubleshooting</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-12">
                                                    <p className="p-lg">Your Name: </p>
                                                    <input type="text" id="name" className="form-control" required placeholder="Your Name*"
                                                           value={this.state.name}
                                                           autoComplete="off" onChange={(e) => this.handleChange(e)}/>
                                                </div>
                                                <div className="col-md-12">
                                                    <p className="p-lg">Your Contact: </p>
                                                    <input type="number" id="contact" className="form-control" required placeholder="Your Contact*"
                                                           value={this.state.contact}
                                                           autoComplete="off" onChange={(e) => this.handleChange(e)}/>
                                                </div>
                                                <div className="col-md-12">
                                                    <p className="p-lg">Your Email Address: </p>
                                                    <input type="text" id="email" className="form-control" placeholder="Email Address" value={this.state.email}
                                                           autoComplete="off" onChange={(e) => this.handleChange(e)}/>
                                                </div>
                                                <div className="col-md-12">
                                                    <p className="p-lg">Explain your question in details: </p>
                                                    <textarea className="form-control" id="message" rows="6" required placeholder="I need help with...*"
                                                              autoComplete="off" onChange={(e) => this.handleChange(e)} value={this.state.message}/>
                                                </div>
                                                <div className="col-md-12 mt-15 form-btn text-right">
                                                    <button type="submit" id="submit" className="btn btn-skyblue tra-grey-hover">
                                                        {!this.state.isFormSubmitting ? 'Submit Request' :
                                                            <Messaging color="#bababa" width="10px" height="8px" style={{justifyContent: "center"}}/>}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Contact;