import React from 'react';
import Preloader from "../../Library/Preloader";
import DocumentTitler from "../../Library/DocumentTitler";
import '../Pages/Services.css'
import {PROJECT_TYPES} from "../../Library/Constants";
import {Link} from "react-router-dom";

const projects = [
    {
        projectName: "Network Maintenance",
        projectThumb: "/assets/images/portfolio/port_11.png",
        projectImage: "/assets/images/portfolio/port_11.png",
        projectUrl: "",
        projectType: PROJECT_TYPES.NETWORK
    },
    {
        projectName: "KHOJKAR (Logo)",
        projectThumb: "/assets/images/portfolio/port_1.png",
        projectImage: "/assets/images/portfolio/port_1.png",
        projectUrl: "",
        projectType: PROJECT_TYPES.DESIGN
    },
    {
        projectName: "Maulana Azad University (Logo)",
        projectThumb: "/assets/images/portfolio/port_2.png",
        projectImage: "/assets/images/portfolio/port_2.png",
        projectUrl: "",
        projectType: PROJECT_TYPES.DESIGN
    },
    {
        projectName: "Adarsh MITR (Logo)",
        projectThumb: "/assets/images/portfolio/port_10.png",
        projectImage: "/assets/images/portfolio/port_10.png",
        projectUrl: "",
        projectType: PROJECT_TYPES.DESIGN
    },
    {
        projectName: "Adarsh MITR",
        projectThumb: "/assets/images/portfolio/port_7.png",
        projectImage: "/assets/images/portfolio/port_7.png",
        projectUrl: "https://play.google.com/store/apps/details?id=com.khaleejinfotech.adarshmitr",
        projectType: PROJECT_TYPES.MOB_APP
    },
    {
        projectName: "Adarsh Muslim Newspaper",
        projectThumb: "/assets/images/portfolio/port_4.png",
        projectImage: "/assets/images/portfolio/port_4.png",
        projectUrl: "https://adarshmuslim.com",
        projectType: PROJECT_TYPES.WEBSITE
    },
    {
        projectName: "Maulana Azad University",
        projectThumb: "/assets/images/portfolio/port_3.png",
        projectImage: "/assets/images/portfolio/port_3.png",
        projectUrl: "https://mualanaazaduniversity.in",
        projectType: PROJECT_TYPES.WEBSITE
    },
    {
        projectName: "Adarsh Muslim Publication",
        projectThumb: "/assets/images/portfolio/port_6.png",
        projectImage: "/assets/images/portfolio/port_6.png",
        projectUrl: "https://adarshmuslim.in",
        projectType: PROJECT_TYPES.WEBSITE
    },
    {
        projectName: "Marwar Muslim Educational and Welfare Society",
        projectThumb: "/assets/images/portfolio/port_5.png",
        projectImage: "/assets/images/portfolio/port_5.png",
        projectUrl: "https://marwarimuslim.in",
        projectType: PROJECT_TYPES.WEBSITE
    },
    {
        projectName: "KHOJKAR App",
        projectThumb: "/assets/images/portfolio/port_8.png",
        projectImage: "/assets/images/portfolio/port_8.png",
        projectUrl: "https://play.google.com/store/apps/details?id=com.khaleejinfotech.khojkar",
        projectType: PROJECT_TYPES.MOB_APP
    }, {
        projectName: "KABADKHANA App",
        projectThumb: "/assets/images/portfolio/port_9.png",
        projectImage: "/assets/images/portfolio/port_9.png",
        projectUrl: "",
        projectType: PROJECT_TYPES.MOB_APP
    },
]

function About(props) {
    return (
        <>
            <Preloader state={false}/>
            <DocumentTitler title="Services"/>
            <section className=" inner-page-hero  bg-lightgrey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-title title-02 mb-85">
                                <h3 className="h3-xl">Services</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="projects-1" className="bg-inner mt-50 division">
                <div className="container">
                    <div className="col-md-12 pb-55">
                        <div className="row row-cols-1 mb-55">
                            <div className="col">
                                <div className="section-title title-02 mb-75">
                                    <h3 className="h4-sm text-start">We Made Plan And We Do Success Our Plan</h3>
                                </div>
                                <div className="fbox-4-wrapper fbox-4-wide">
                                    <div className="row row-cols-1 row-cols-md-2">
                                        <div className="col">
                                            <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                                                <div className="fbox-ico">
                                                    <div className="ico-65">
                                                        <span className="flaticon-idea"/>
                                                    </div>
                                                </div>
                                                <div className="fbox-txt">
                                                    <h5 className="h5-sm">Graphic Design</h5>
                                                    <p className="p-lg">Graphic design is a way for a company to connect with their audience more visual, and
                                                        build a brand. Good design will make a difference in the experience your audience has with your
                                                        brand.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="fbox-4 pl-25 mb-40 wow fadeInUp">
                                                <div className="fbox-ico">
                                                    <div className="ico-65">
                                                        <span className="flaticon-web-design"/>
                                                    </div>
                                                </div>
                                                <div className="fbox-txt">
                                                    <h5 className="h5-sm">Website Development</h5>
                                                    <p className="p-lg">Your website is the most important component of your company’s marketing plan. As
                                                        website design and development technology has progressed over the years, you now have more digital
                                                        marketing tools than ever before.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                                                <div className="fbox-ico">
                                                    <div className="ico-65">
                                                        <span className="flaticon-algorithm"/>
                                                    </div>
                                                </div>
                                                <div className="fbox-txt">
                                                    <h5 className="h5-sm">Web Applications</h5>
                                                    <p className="p-lg">Web application programs that reside on remote servers and are delivered to the user's
                                                        device over the Internet. A web application (web app) does not need to be downloaded and is instead
                                                        accessed through a network, like Accounting Software and Content Management System (CMS).</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="fbox-4 pl-25 mb-40 wow fadeInUp">
                                                <div className="fbox-ico">
                                                    <div className="ico-65">
                                                        <span className="flaticon-google-play"/>
                                                    </div>
                                                </div>
                                                <div className="fbox-txt">
                                                    <h5 className="h5-sm">Android App Development</h5>
                                                    <p className="p-lg">Mobile apps are easy to use, facile to navigate, and highly responsive. We are offering
                                                        mobile application development services.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                                                <div className="fbox-ico">
                                                    <div className="ico-65">
                                                        <span className="flaticon-cloud-network"/>
                                                    </div>
                                                </div>
                                                <div className="fbox-txt">
                                                    <h5 className="h5-sm">IT/Networking</h5>
                                                    <p className="p-lg">Networking is a very crucial task in a business to run it properly, it involves the
                                                        processes of building, using and maintaining computer networks – including hardware, software and
                                                        protocols – so that multiple computing devices can share data.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title title-02 mb-75">
                                    <h3 className="h4-sm ">Our recent projects</h3>
                                </div>
                            </div>
                            {projects.map(project => {
                                return (
                                    <div className="col-md-3 mb-30 wow fadeInUp">
                                        <div className="project-preview shadow-sm ">
                                            <div className="hover-overlay">
                                                <img className="img-fluid" src={project.projectThumb} alt="project-preview"/>
                                                <div className="item-overlay"/>
                                            </div>
                                        </div>
                                        <div className="project-txt pr-5">
                                            <p className="p-md grey-color">{project.projectType}</p>
                                            <h5 className="h6-sm">
                                                {project.projectUrl !== "" ?
                                                    <Link to={{pathname: `${project.projectUrl}`}} target="_blank"><span
                                                        className="flaticon-click"/>{project.projectName} </Link>
                                                    : project.projectName}
                                            </h5>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default About;