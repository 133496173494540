import React, {Component} from 'react';
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";
import $ from 'jquery'

class Header extends Component {
    componentDidMount() {
        $('.wsmenu-list li  a').on("click", function () {
            $('body').toggleClass('wsactive');
        });
    }

    render() {
        return (
            <>
                <header id="header" className="header tra-menu navbar-dark">
                    <div className="header-wrapper">
                        <div className="wsmobileheader clearfix">
                            <span className="smllogo">
                                <img src="/assets/images/logo.png" alt="mobile-logo"/>
                            </span>
                            <a id="wsnavtoggle" className="wsanimated-arrow"><span/></a>
                        </div>
                        <div className="wsmainfull menu clearfix">
                            <div className="wsmainwp clearfix">
                                <div className="desktoplogo">
                                    <HashLink to="/#" className="logo-black">
                                        <img src="/assets/images/logo.png" alt="header-logo"/>
                                    </HashLink>
                                </div>
                                <div className="desktoplogo">
                                    <HashLink to="/#" className="logo-white">
                                        <img src="/assets/images/logo-white.png" alt="header-logo"/>
                                    </HashLink>
                                </div>
                                <nav className="wsmenu clearfix">
                                    <ul className="wsmenu-list nav-skyblue-hover">
                                        <li><Link to="/#">Home</Link></li>
                                        <li><Link to="/about">About </Link></li>
                                        <li><Link to="/services">Services</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                        {/*<li class="nl-simple white-color header-socials ico-20 clearfix" aria-haspopup="true">
                                            <span><a href="#" class="ico-facebook"><span class="flaticon-facebook"></span></a></span>
                                            <span><a href="#" class="ico-twitter"><span class="flaticon-twitter"></span></a></span>
                                            <span><a href="#" class="ico-instagram"><span class="flaticon-instagram"></span></a></span>
                                            <span><a href="#" class="ico-dribbble"><span class="flaticon-dribbble"></span></a></span>
                                        </li>*/}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default Header;