import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel'
import Preloader from "../Library/Preloader";
import DocumentTitler from "../Library/DocumentTitler";
import Axios from 'axios';
import {CONSTANTS} from "../Library/Constants";
import $ from 'jquery'
import {Messaging} from "react-cssfx-loading";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

const initState = {
    isFormSubmitting: false,
    quotName: "",
    quotPhone: ""
}

const reviews = [
    {
        review: "We used KHALEEJ Infotech logo creation services along with their app development services. They have been a pleasure to work with and have been responsive to all questions asked. We have recommended them to some of our clients and will continue to do so as we have been very happy with the logos and mobile app that they have created for us.",
        person: "Absar Alam",
        company: "KHOJKAR"
    },
    {
        review: "We have been using KHALEEJ Infotech services for the last four years and are happy with the quality of service they provide. Social Media management requires a 24/7 ownership and we have been able to get that from KHALEEJ Infotech. Thanks to KHALEEJ Infotech & their team for being a great partner in our journey.",
        person: "Saleem Khilji",
        company: "Adarsh Muslim Newspaper"
    },
    {
        review: "We have worked with KHALEEJ Infotech for many years and KHALEEJ Infotech has developed several websites for us. Their expertise in website development, graphic design is unmatchable in the industry along with their quality of work and ongoing support.",
        person: "Mr. Atique",
        company: "Marwar Muslim Educational &amp; Welfare Society"
    },
    {
        review: "Fantastic work! I am just impressed by their service quality and working strategy. I hired them for the development of my online store and they have satisfied me to the full by delivering the exceptional solution. They have a great expertise, are dedicated, attentive, talented and care much about the client needs. Highly recommended.",
        person: "Kamal Gupta",
        company: "KBG Mart"
    }
]
const quotBtnSubmitting = {color: "#1d293f!important", backgroundColor: "transparent", borderColor: "#ccc !important"}
const quotBtnRest = {color: "#fff!important", backgroundColor: "#0195ff", borderColor: "#0195ff !important"}

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    handleChange(e) {
        this.setState({[e.target.id]: e.target.value})
    }

    getQuotation(e) {
        e.preventDefault();
        let btnSubmit = $(e.target).find("#submit")
        btnSubmit.css(quotBtnSubmitting);
        this.setState({isFormSubmitting: true})
        Axios.post(`${CONSTANTS.URL}enquire`, this.state, {
            headers: {
                'Authorization': 'demoContent'
            }
        }).then(res => {
            this.setState({isFormSubmitting: false, quotName: "", quotPhone: ""})
            btnSubmit.css(quotBtnRest)
            if (!res.data.error) {
                toast.success(res.data.msg)
            } else {
                toast.dark(res.data.msg)
            }
        })
    }

    render() {
        return (
            <>
                <Preloader state={false}/>
                <DocumentTitler/>
                <ToastContainer/>
                <section id="hero-9" className="bg-fixed hero-section division">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-7 offset-md-5 col-lg-5 offset-lg-7">
                                <div id="hero-9-form">
                                    <h4 className="h4-xs">Get your free quotation call today.</h4>
                                    <p className="p-md"/>
                                    <form name="requestForm" className="row request-form" onSubmit={(e) => this.getQuotation(e)}>
                                        <div className="col-md-12">
                                            <input type="text" id="quotName" className="form-control" placeholder="Enter Your Name*" autoComplete="off"
                                                   value={this.state.quotName} onChange={(e) => this.handleChange(e)}
                                                   required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="number" id="quotPhone" className="form-control" placeholder="Enter Your Phone*" autoComplete="off"
                                                   value={this.state.quotPhone} onChange={(e) => this.handleChange(e)}
                                                   required/>
                                        </div>
                                        <div className="col-md-12 form-btn mt-5">
                                            <button type="submit" id="submit" className="btn btn-md btn-skyblue tra-grey-hover">
                                                {!this.state.isFormSubmitting ? <span>Get Quotation Call</span> :
                                                    <Messaging color="#bababa" width="10px" height="8px" style={{justifyContent: "center"}}/>}
                                            </button>
                                        </div>
                                        <div className="col-md-12 request-form-msg text-center">
                                            <span className="loading"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wave-shape-bottom">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 140">
                            <path fill="#ffffff" fillOpacity="1"
                                  d="M0,96L120,106.7C240,117,480,139,720,138.7C960,139,1200,117,1320,106.7L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"/>
                        </svg>
                    </div>
                </section>
                <section id="features-4" className="wide-60 features-section division">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="section-title title-02 mb-85">
                                    <h3 className="h3-xl">We have everything you need to convert your ideas into reality</h3>
                                </div>
                            </div>
                        </div>
                        <div className="fbox-4-wrapper fbox-4-wide">
                            <div className="row row-cols-1 row-cols-md-2">
                                <div className="col">
                                    <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                                        <div className="fbox-ico">
                                            <div className="ico-65">
                                                <span className="flaticon-idea"/>
                                            </div>
                                        </div>
                                        <div className="fbox-txt">
                                            <h5 className="h5-sm">Graphic Design</h5>
                                            <p className="p-lg">Graphic design is a way for a company to connect with their audience more visual, and build a
                                                brand. Good design will make a difference in the experience your audience has with your brand.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="fbox-4 pl-25 mb-40 wow fadeInUp">
                                        <div className="fbox-ico">
                                            <div className="ico-65">
                                                <span className="flaticon-web-design"/>
                                            </div>
                                        </div>
                                        <div className="fbox-txt">
                                            <h5 className="h5-sm">Website Development</h5>
                                            <p className="p-lg">Your website is the most important component of your company’s marketing plan. As website design
                                                and development technology has progressed over the years, you now have more digital marketing tools than ever
                                                before.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                                        <div className="fbox-ico">
                                            <div className="ico-65">
                                                <span className="flaticon-algorithm"/>
                                            </div>
                                        </div>
                                        <div className="fbox-txt">
                                            <h5 className="h5-sm">Web Applications</h5>
                                            <p className="p-lg">Web application programs that reside on remote servers and are delivered to the user's device
                                                over the Internet. A web application (web app) does not need to be downloaded and is instead accessed through a
                                                network, like Accounting Software and Content Management System (CMS).</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="fbox-4 pl-25 mb-40 wow fadeInUp">
                                        <div className="fbox-ico">
                                            <div className="ico-65">
                                                <span className="flaticon-google-play"/>
                                            </div>
                                        </div>
                                        <div className="fbox-txt">
                                            <h5 className="h5-sm">Android App Development</h5>
                                            <p className="p-lg">Mobile apps are easy to use, facile to navigate, and highly responsive. We are offering mobile
                                                application development services.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="fbox-4 pr-25 mb-40 wow fadeInUp">
                                        <div className="fbox-ico">
                                            <div className="ico-65">
                                                <span className="flaticon-cloud-network"/>
                                            </div>
                                        </div>
                                        <div className="fbox-txt">
                                            <h5 className="h5-sm">IT/Networking</h5>
                                            <p className="p-lg">Networking is a very crucial task in a business to run it properly, it involves the processes of
                                                building, using and maintaining computer networks – including hardware, software and protocols – so that
                                                multiple computing devices can share data.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="reviews-1" className="bg-whitesmoke-gradient wide-100 reviews-section division">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="section-title title-02 mb-75">
                                    <h3 className="h3-xl">Don't trust our words. See what our clients say about us</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <OwlCarousel items={3} loop={true} autoplay={true} autoplayTimeout={4500} autoplayHoverPause={true} smartSpeed={1500}
                                             responsive={{0: {items: 1}, 767: {items: 1}, 768: {items: 2}, 991: {items: 3}, 1000: {items: 1}}} dots={true}>
                                    {reviews.map(review => {
                                        return (
                                            <div className="review-1">
                                                <div className="review-1-ico ico-25">
                                                    <span className="flaticon-left-quote"/>
                                                </div>
                                                <div className="review-1-txt">
                                                    <p className="p-lg" dangerouslySetInnerHTML={{__html: review.review}}/>
                                                    <div className="author-data clearfix">
                                                        <div className="p-0 review-author">
                                                            <h6 className="h6-xl" dangerouslySetInnerHTML={{__html: review.person}}/>
                                                            <p className="p-md" dangerouslySetInnerHTML={{__html: review.company}}/>
                                                            <div className="review-rating ico-15 yellow-color">
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-1"/>
                                                                <span className="flaticon-star-half-empty"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Main;